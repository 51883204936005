import { API_REQUEST_FAILED } from '../actions/actions';
import { NoAuthError } from '../../api/utils';
import { UserAccount } from '../../api/userClient';
import {
    FETCH_MY_INSTANCES_COMPLETED,
    FETCH_MY_INSTANCES_REQUESTED,
} from '../actions/instancesActions';
import {
    SEARCH_SCRIPTS_COMPLETED,
    SEARCH_SCRIPTS_REQUESTED,
} from '../actions/scriptsActions';
import { FETCH_USER_COMPLETED } from '../actions/sharedActions';

export interface SharedState {
    isLoading: boolean;
    loadingCounter: number;
    user: UserAccount | null;
}

const sharedReducer = (
    state: SharedState = {
        isLoading: false,
        loadingCounter: 0,
        user: null,
    },
    action,
): SharedState => {
    if (action.type === FETCH_USER_COMPLETED) {
        state = {
            ...state,
            user: action.user,
        };
    }

    if (
        action.type.endsWith('_REQUESTED') &&
        action.type !== SEARCH_SCRIPTS_REQUESTED &&
        action.type !== FETCH_MY_INSTANCES_REQUESTED
    ) {
        const newCounter = state.loadingCounter + 1;
        return {
            ...state,
            isLoading: newCounter > 0,
            loadingCounter: newCounter,
        };
    } else if (
        action.type.endsWith('_COMPLETED') &&
        action.type !== SEARCH_SCRIPTS_COMPLETED &&
        action.type !== FETCH_MY_INSTANCES_COMPLETED
    ) {
        const newCounter = Math.max(0, state.loadingCounter - 1);
        return {
            ...state,
            isLoading: newCounter > 0,
            loadingCounter: newCounter,
        };
    } else {
        switch (action.type) {
            case API_REQUEST_FAILED: {
                const newCounter = Math.max(0, state.loadingCounter - 1);
                if (action.e === NoAuthError && window) {
                    window.location.replace('/community/index.php?/login/');
                }
                console.error(action.e);
                return {
                    ...state,
                    isLoading: newCounter > 0,
                    loadingCounter: newCounter,
                };
            }
        }
    }

    return state;
};

export default sharedReducer;
