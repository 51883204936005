import React from 'react';

import styles from './Footer.module.scss';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDiscord,
    faFacebook,
    faGithub,
    faTwitter,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
    return (
        <div className={styles.root}>
            <div>
                <p className={styles.text}>
                    © 2020 Merton Labs s.r.o | RuneScape is a registered
                    trademark of Jagex Ltd. | Use of PowBot and its services is
                    not endorsed or recommended by Jagex Ltd. | All prices
                    listed are incl. VAT
                    <br /> <a href={'/privacy-policy'}>Privacy Policy</a>
                    &nbsp;-&nbsp;
                    <a href={'/terms-and-conditions'}>Terms and Conditions</a>
                    &nbsp;-&nbsp;<a href={'/powbot-license'}>PowBot License</a>
                </p>
            </div>
            <div className={styles.socials}>
                <a
                    href="https://github.com/powbot/"
                    target="_blank"
                    rel="noopener"
                >
                    <IconButton
                        aria-label="github"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        className={styles.githubIcon}
                    >
                        <FontAwesomeIcon icon={faGithub} />
                    </IconButton>
                </a>

                <a
                    href="https://discord.com/invite/aBG7YqtXeF"
                    target="_blank"
                    rel="noopener"
                >
                    <IconButton
                        aria-label="discord"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        className={styles.discordIcon}
                    >
                        <FontAwesomeIcon icon={faDiscord} />
                    </IconButton>
                </a>

                <a
                    href="https://twitter.com/powbot_"
                    target="_blank"
                    rel="noopener"
                >
                    <IconButton
                        aria-label="twitter"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        className={styles.twitterIcon}
                    >
                        <FontAwesomeIcon icon={faTwitter} />
                    </IconButton>
                </a>

                <a
                    href="https://facebook.com/powbot.org"
                    target="_blank"
                    rel="noopener"
                >
                    <IconButton
                        aria-label="facebook"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        className={styles.facebookIcon}
                    >
                        <FontAwesomeIcon icon={faFacebook} />
                    </IconButton>
                </a>

                <a
                    href="https://www.youtube.com/channel/UCCjN5msoo4-x7Uzgczt12uQ"
                    target="_blank"
                    rel="noopener"
                >
                    <IconButton
                        aria-label="youtube"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        className={styles.youtubeIcon}
                    >
                        <FontAwesomeIcon icon={faYoutube} />
                    </IconButton>
                </a>
            </div>
        </div>
    );
}
