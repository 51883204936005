import { getMe, UserAccount } from '../../api/userClient';
import { PageContext } from '../../api/utils';
import { apiRequestFailed } from './actions';

export const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED';
export const FETCH_USER_COMPLETED = 'FETCH_USER_COMPLETED';

export const fetchUserRequested = () => ({
    type: FETCH_USER_REQUESTED,
});

export const fetchUserCompleted = (user: UserAccount | null) => ({
    type: FETCH_USER_COMPLETED,
    user,
});

export const fetchUser = (ctx: PageContext | null) => {
    return async (dispatch) => {
        dispatch(fetchUserRequested());
        try {
            const user = await getMe(ctx);
            return dispatch(fetchUserCompleted(user));
        } catch (e) {
            dispatch(apiRequestFailed('Failed to fetch user', e));
        }
    };
};
