import scriptsReducer, { ScriptsState } from './scriptsReducer';
import { combineReducers } from 'redux';
import subscriptionsReducer, {
    SubscriptionsState,
} from './subscriptionsReducer';
import sharedReducer, { SharedState } from './sharedReducer';
import instancesReducer, { InstancesState } from './instancesReducer';
import rsAccountsReducer, { RSAccountsState } from './rsAccountsReducer';
import tasksReducer, { TasksState } from './tasksReducer';
import { statsReducer, StatsState } from './statsReducer';

export interface RootState {
    scripts: ScriptsState;
    subscriptions: SubscriptionsState;
    instances: InstancesState;
    rsAccounts: RSAccountsState;
    tasks: TasksState;
    shared: SharedState;
    stats: StatsState;
}

const rootReducer = combineReducers({
    scripts: scriptsReducer,
    subscriptions: subscriptionsReducer,
    instances: instancesReducer,
    rsAccounts: rsAccountsReducer,
    tasks: tasksReducer,
    shared: sharedReducer,
    stats: statsReducer,
});

export default rootReducer;
