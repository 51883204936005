import { createMuiTheme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const theme = createMuiTheme({
    overrides: {
        MuiCard: createStyles({
            root: {
                backgroundColor: '#373737',
                color: 'white',
            },
        }),
        MuiCheckbox: createStyles({
            root: {
                color: 'white',
            },
        }),
        MuiInput: createStyles({
            input: {
                color: 'white',
            },
            underline: {
                '&&&&:hover:before': {
                    borderBottom: '1px solid white',
                },
                '&&&&:before': {
                    borderBottom: '1px solid white',
                },
                '&&&&:after': {
                    borderBottom: '2px solid white',
                },
            },
        }),
        MuiSelect: createStyles({
            root: {
                color: 'white',
            },
            icon: {
                color: 'white',
            },
            select: {
                '&:not([multiple]) option': {
                    backgroundColor: '#373737',
                },
            },
        }),
        MuiFormLabel: createStyles({
            root: {
                color: 'white',
                '&$focused': {
                    color: 'white',
                },
            },
        }),
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            'bebas',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
        h1: { fontSize: 52 },
        h2: { fontSize: 44 },
        h3: { fontSize: 36 },
        h4: { fontSize: 28 },
        h5: { fontSize: 20 },
        body1: { fontFamily: 'Martel Sans' },
        body2: { fontFamily: 'Martel Sans' },
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#121212',
            light: 'white',
            contrastText: '#9e9e9e',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#0066ff',
            main: '#127dd7',
            // dark: will be calculated from palette.secondary.main,
            contrastText: 'white',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

export default theme;
