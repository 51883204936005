import {
    ClientType,
    SimpleUsageStatisticsResponse,
} from '../../api/statsClient';
import { FETCH_STATS_COMPLETED } from '../actions/statsActions';

export interface StatsState {
    stats: { [key: string]: SimpleUsageStatisticsResponse | null };
}

export const statsReducer = (
    state: StatsState = {
        stats: {
            [ClientType.MOBILE.toString()]: {
                id: null,
                usersOnline: 0,
                instancesOnline: 0,
                xpGainedToday: 0,
                levelsGainedToday: 0,
                xpGainedThisMonth: 0,

                levelsGainedThisMonth: 0,
                xpGainedAllTime: 0,
                levelsGainedAllTime: 0,
                clientType: ClientType.MOBILE,
                createdAt: '',
            },
        },
    },
    action,
): StatsState => {
    switch (action.type) {
        case FETCH_STATS_COMPLETED:
            return {
                ...state,
                stats: {
                    ...state.stats,
                    [action.stats?.clientType?.toString() || '']: action.stats,
                },
            };

        default:
            return { ...state };
    }
};
