import { SimpleBotInstanceResponse } from '../../api/instancesClient';
import {
    FETCH_MY_INSTANCES_COMPLETED,
    SET_START_SCRIPT_INSTANCE as SET_START_SCRIPT_INSTANCE,
} from '../actions/instancesActions';

export interface InstancesState {
    myInstances: SimpleBotInstanceResponse[] | null;
    startScriptInstance: SimpleBotInstanceResponse | null;
}

const instancesReducer = (
    state: InstancesState = {
        myInstances: [],
        startScriptInstance: null,
    },
    action,
): InstancesState => {
    switch (action.type) {
        case SET_START_SCRIPT_INSTANCE:
            return { ...state, startScriptInstance: action.instance };
        case FETCH_MY_INSTANCES_COMPLETED:
            return { ...state, myInstances: action.myInstances };
        default:
            return { ...state };
    }
};

export default instancesReducer;
