import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import DesktopAppBar from './DesktopAppBar';
import MobileAppBar from './MobileAppBar';

export default function Header() {
    const isDesktop = useMediaQuery('(min-width: 1000px)');
    return isDesktop ? (
        <DesktopAppBar></DesktopAppBar>
    ) : (
        <MobileAppBar></MobileAppBar>
    );
}
