export interface TasksState {}

const tasksReducer = (state: TasksState = {}, action): TasksState => {
    switch (action.type) {
        default:
            return { ...state };
    }
};

export default tasksReducer;
