import React, { useEffect } from 'react';
import {
    AppBar,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    Toolbar,
    Typography,
} from '@material-ui/core';

import styles from './Header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faGithub } from '@fortawesome/free-brands-svg-icons';
import { getMe, isDeveloper } from '../api/userClient';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NotificationBar from './NotificationBar';

function MobileAppBar() {
    const [state, setState] = React.useState({
        me: null,
        drawerOpen: false,
    });

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, drawerOpen: open });
    };

    useEffect(() => {
        async function auth() {
            if (state.me == null) {
                try {
                    state.me = await getMe(null);
                } catch (e) {
                    if (process.env.NODE_ENV !== 'production') {
                        console.error(e);
                    }
                    state.me = null;
                }
            }
        }

        auth();
    });

    return (
        <div className={styles.root}>
            <NotificationBar me={state.me} />
            <AppBar className={styles.appBar} position="sticky">
                <Toolbar>
                    <img
                        src="/icon.webp"
                        alt="icon"
                        className={styles.iconImg}
                    />
                    <div className={styles.pageLinks}>
                        <Typography variant="h6" className={styles.title}>
                            <Link href="/">Home</Link>
                        </Typography>
                    </div>
                    <div>
                        <a
                            href="https://discord.com/invite/aBG7YqtXeF"
                            rel="noopener"
                            target="_blank"
                        >
                            <IconButton
                                aria-label="discord"
                                color="inherit"
                                className={styles.discordIcon}
                            >
                                <FontAwesomeIcon icon={faDiscord} />
                            </IconButton>
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://github.com/powbot/api"
                            rel="noopener"
                            target="_blank"
                        >
                            <IconButton
                                aria-label="github"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                className={styles.githubIcon}
                            >
                                <FontAwesomeIcon icon={faGithub} />
                            </IconButton>
                        </a>
                    </div>
                    <div>
                        <IconButton
                            aria-label="hamburger"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            className={styles.hamburgerIcon}
                            onClick={toggleDrawer(true)}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            <React.Fragment key="drawer">
                <Drawer
                    anchor="left"
                    open={state.drawerOpen}
                    onClose={toggleDrawer(false)}
                >
                    <List>
                        <ListItem button>
                            <Link href="/">Home</Link>
                        </ListItem>
                        <ListItem button>
                            <Link href="/scripts">Scripts</Link>
                        </ListItem>
                        <ListItem button>
                            <Link href="/community/index.php?/subscriptions">
                                Upgrade
                            </Link>
                        </ListItem>
                        <ListItem button>
                            <Link href="/community/index.php">Community</Link>
                        </ListItem>
                        {/* <ListItem button>
                            <Link href="/bot-manager">Bot Manager</Link>
                        </ListItem> */}
                        <ListItem button>
                            <Link href="/blog">Blog</Link>
                        </ListItem>
                        <ListItem button>
                            <Link href="https://docs.powbot.org">Docs</Link>
                        </ListItem>
                        <ListItem button>
                            <Link href="/discord">Discord</Link>
                        </ListItem>
                        {state.me?.userId > 0 &&
                            isDeveloper(state.me.userGroupId) && (
                                <ListItem button>
                                    <Link href="/developer/">Developer</Link>
                                </ListItem>
                            )}
                    </List>
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default MobileAppBar;
