import { SimpleRSAccountResponse } from '../../api/rsAccountsClient';
import { FETCH_MY_RS_ACCOUNTS_COMPLETED } from '../actions/rsAccountsActions';

export interface RSAccountsState {
    myAccounts: SimpleRSAccountResponse[] | null;
}

const rsAccountsReducer = (
    state: RSAccountsState = {
        myAccounts: [],
    },
    action,
): RSAccountsState => {
    switch (action.type) {
        case FETCH_MY_RS_ACCOUNTS_COMPLETED:
            return { ...state, myAccounts: action.myAccounts };
        default:
            return { ...state };
    }
};

export default rsAccountsReducer;
