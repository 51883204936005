import { PaginatedResponse } from '../../api/utils';
import {
    ProductSubscriptionType,
    ScriptCategory,
    SimpleProductResponse,
} from '../../api/productsClient';
import {
    FETCH_AUTHORED_SCRIPTS_COMPLETED,
    FETCH_FEATURED_SCRIPTS_COMPLETED,
    FETCH_SCRIPTS_COMPLETED,
    SEARCH_SCRIPTS_REQUESTED,
} from '../actions/scriptsActions';

export interface ScriptsState {
    scripts: PaginatedResponse<SimpleProductResponse> | null;
    featuredScripts: Array<SimpleProductResponse> | null;
    authoredScripts: Array<SimpleProductResponse> | null;
    filters: ScriptsFilters;
    filtered: boolean;
    page: number;
}

export interface ScriptsFilters {
    category: ScriptCategory | null;
    text: string;
    added: boolean;
    mobileReady: boolean;
    subscriptionType: ProductSubscriptionType | null;
}

const scriptsReducer = (
    state: ScriptsState = {
        scripts: { items: [], totalElements: 0, totalPages: 0, currentPage: 0 },
        featuredScripts: [],
        authoredScripts: [],
        filters: {
            category: null,
            text: '',
            subscriptionType: null,
            added: false,
            mobileReady: false,
        },
        filtered: false,
        page: 0,
    },
    action,
): ScriptsState => {
    switch (action.type) {
        case SEARCH_SCRIPTS_REQUESTED:
            return {
                ...state,
                scripts: {
                    items: [],
                    currentPage: 0,
                    totalElements: 0,
                    totalPages: -1,
                },
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        case FETCH_SCRIPTS_COMPLETED:
            return {
                ...state,
                scripts: {
                    ...action.page,
                    items: (state.scripts.items || []).concat(
                        action.page.items || [],
                    ),
                },
                filtered: action.filtered,
            };
        case FETCH_FEATURED_SCRIPTS_COMPLETED:
            return {
                ...state,
                featuredScripts: action.featuredScripts || [],
            };
        case FETCH_AUTHORED_SCRIPTS_COMPLETED:
            return {
                ...state,
                authoredScripts: action.authoredScripts || [],
            };
        default:
            return { ...state };
    }
};

export default scriptsReducer;
