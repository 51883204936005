import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import '../styles/globals.scss';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../styles/theme';
import ReactGA from 'react-ga';
import { wrapper } from '../redux/store';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
import { CssBaseline } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/node';

config.autoAddCss = false; /* eslint-disable import/first */

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    });
}

// @ts-ignore
function MyApp({ Component, pageProps, err }: AppProps) {
    useEffect(() => {
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.init('2749527168709274');
        ReactGA.initialize('UA-156082793-7');
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    });
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <Header />
                <Component {...pageProps} err={err} />
                <footer>
                    <Footer />
                </footer>
            </ThemeProvider>
        </React.Fragment>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};

export default wrapper.withRedux(MyApp);
