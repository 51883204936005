import { SimpleProductSubscriptionResponse } from '../../api/productsClient';
import { FETCH_MY_SUBSCRIPTIONS_COMPLETED } from '../actions/subscriptionsActions';

export interface SubscriptionsState {
    mySubscriptions: SimpleProductSubscriptionResponse[] | null;
}

const subscriptionsReducer = (
    state: SubscriptionsState = {
        mySubscriptions: [],
    },
    action,
): SubscriptionsState => {
    switch (action.type) {
        case FETCH_MY_SUBSCRIPTIONS_COMPLETED:
            return { ...state, mySubscriptions: action.mySubscriptions };
        default:
            return { ...state };
    }
};

export default subscriptionsReducer;
