import React, { useEffect } from 'react';
import {
    AppBar,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';

import styles from './Header.module.scss';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faGithub } from '@fortawesome/free-brands-svg-icons';
import { getMe, isDeveloper } from '../api/userClient';
import NotificationBar from './NotificationBar';

function DesktopAppBar() {
    const [me, setMe] = React.useState(null);

    useEffect(() => {
        async function auth() {
            if (me == null) {
                try {
                    const me = await getMe(null);
                    setMe(me);
                } catch (e) {
                    if (process.env.NODE_ENV !== 'production') {
                        console.error(e);
                    }
                    setMe(null);
                }
            }
        }

        auth();
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = null;
    return (
        <div className={styles.root}>
            <NotificationBar me={me} />
            <AppBar className={styles.appBar} position="sticky">
                <Toolbar>
                    <img
                        src="/icon.webp"
                        alt="icon"
                        className={styles.iconImg}
                    />
                    <div className={styles.pageLinks}>
                        <Typography variant="h6" className={styles.title}>
                            <Link href="/">
                                <a>Home</a>
                            </Link>
                        </Typography>
                        <Typography variant="h6" className={styles.title}>
                            <Link href="/scripts">
                                <a>Scripts</a>
                            </Link>
                        </Typography>
                        <Typography variant="h6" className={styles.title}>
                            <Link href="/community/index.php?/subscriptions">
                                <a>Upgrade</a>
                            </Link>
                        </Typography>
                        {/* <Typography variant="h6" className={styles.title}>
                            <Badge color="secondary" badgeContent={'NEW'}>
                                <Link href="/bot-manager">
                                    <a>Bot Manager</a>
                                </Link>
                            </Badge>
                        </Typography> */}
                        <Typography variant="h6" className={styles.title}>
                            <Link href="/community/index.php">
                                <a>Community</a>
                            </Link>
                        </Typography>
                        <Typography variant="h6" className={styles.title}>
                            <Link href="/blog">
                                <a>Blog</a>
                            </Link>
                        </Typography>
                        <Typography variant="h6" className={styles.title}>
                            <Link href="https://docs.powbot.org">
                                <a>Docs</a>
                            </Link>
                        </Typography>
                        <Typography variant="h6" className={styles.title}>
                            <Link href="/discord">
                                <a>Discord</a>
                            </Link>
                        </Typography>
                        {me?.userId > 0 && isDeveloper(me.userGroupId) && (
                            <Typography variant="h6" className={styles.title}>
                                <Link href="/developer/">
                                    <a>Developer</a>
                                </Link>
                            </Typography>
                        )}
                    </div>
                    <div>
                        <a
                            href="https://discord.com/invite/aBG7YqtXeF"
                            rel="noopener"
                            target="_blank"
                        >
                            <IconButton
                                aria-label="discord"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                className={styles.githubIcon}
                            >
                                <FontAwesomeIcon icon={faDiscord} />
                            </IconButton>
                        </a>
                        <a
                            href="https://github.com/powbot/api"
                            rel="noopener"
                            target="_blank"
                        >
                            <IconButton
                                aria-label="github"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                className={styles.githubIcon}
                            >
                                <FontAwesomeIcon icon={faGithub} />
                            </IconButton>
                        </a>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default DesktopAppBar;
