import React from 'react';
import { UserAccount } from '../api/userClient';
import styles from './NotificationBar.module.scss';
import { Link, Typography } from '@material-ui/core';

interface Props {
    me: UserAccount | null;
}

export default function NotificationBar({ me }: Props) {
    return (
        <>
            {me && me.banned && (
                <div className={styles.banned}>
                    <Typography variant="body2" component="p">
                        Your account has been disabled - if you feel like this
                        was done in error please contact a member of staff using
                        Discord.
                    </Typography>
                </div>
            )}

            {me && me.validating && (
                <div className={styles.validating}>
                    <Typography variant="body2" component="p">
                        You have to verify your e-mail address before you can
                        start using PowBot. Check your mailbox for the
                        validation link or{' '}
                        <Link href="/community/">request a new one</Link>
                    </Typography>
                </div>
            )}
        </>
    );
}
